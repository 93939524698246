"use client"
import { PropsWithChildren } from "react"
import { NextUIProvider } from "@nextui-org/react"

import { ReduxProvider } from "@/lib/redux/redux-provider"
import { AuthProvider } from "@/shared/lib/auth/auth-provider"
import { InternalisationProvider } from "@/shared/lib/internalisation/internalisation-provider"
import en from "@/messages/en.json"
import { AuthProviderProps } from "react-oidc-context"
import { getEnv } from "@/lib/get-env"
import { isSSR } from "@/shared/lib/next-utils"
import { WebStorageStateStore } from "oidc-client-ts"

export function Providers({ children }: PropsWithChildren) {
  const baseUri = getEnv("EMS_FRONTEND_BASE_URI")
  const oidcConfig: AuthProviderProps = {
    authority: getEnv("EMS_FRONTEND_OAUTH_AUTHORITY"),
    client_id: getEnv("EMS_FRONTEND_OAUTH_CLIENT_ID"),
    redirect_uri: `${baseUri}/auth-internal/redirect/`,
    scope: getEnv("EMS_FRONTEND_OAUTH_SCOPE"),
    userStore: isSSR() ? undefined : new WebStorageStateStore({ store: window.localStorage }),
    post_logout_redirect_uri: `${baseUri}/auth-internal/signout/`,
    monitorSession: true,
  }

  return (
    <ReduxProvider>
      <NextUIProvider className="h-full flex flex-col">
        <AuthProvider oidcConfig={oidcConfig}>
          <InternalisationProvider messages={en}>{children}</InternalisationProvider>
        </AuthProvider>
      </NextUIProvider>
    </ReduxProvider>
  )
}
